// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../../app.config'
// import { baseUrl } from '../../../../../app.config'

export const getUserProjectList = createAsyncThunk(
  'userProject/getUserProjectList',
  async (params) => {
    const response = await axios.get(baseUrl() + '/user-projects', {
      params
    })
    return {
      total: response?.data?.count?.total,
      userProjectsList: response.data.data,
      params: { ...params, total: response.data.count.total }
    }
  }
)
export const getUserProjectFilterList = createAsyncThunk(
  'userProject/getUserProjectFilterList',
  async (params) => {
    const response = await axios.get(
      baseUrl() + '/user-projects/getfilteredlist',
      {
        params
      }
    )
    return {
      userProjectsList: response.data.data,
      params: { ...params }
    }
  }
)

export const addUserProjectList = createAsyncThunk(
  'userProject/addUserProjectList',
  async ({ pageNumber, pageSize, projectId, userId, params }, { dispatch }) => {
    try {
      const response = await axios.post(baseUrl() + '/user-projects', {
        projectId,
        userId
      })
      toast.success(response?.data?.message)
      dispatch(
        getUserProjectList({
          pageNumber,
          pageSize,
          ...params
        })
      )
    } catch (error) {
      toast.error(error?.response?.data?.message)
    } finally {
      dispatch(setLoading(false))
    }

    // return user-projects
  }
)

export const updateUserProjectList = createAsyncThunk(
  'userProject/updateUserProjectList',
  async (
    { pageNumber, pageSize, projectId, userId, params, selectedId },
    { dispatch }
  ) => {
    try {
      const response = await axios.put(
        baseUrl() + `/user-projects` + `/${selectedId}`,
        {
          projectId,
          userId
        }
      )
      toast.success(response?.data?.message)
      dispatch(
        getUserProjectList({
          pageNumber,
          pageSize,
          ...params
        })
      )
    } catch (error) {
      toast.error(error?.response?.data?.message)
    } finally {
      dispatch(setLoading(false))
    }
  }
)

export const deleteUserProjectList = createAsyncThunk(
  'userProject/deleteAssignTopic',
  async ({ id, pageNumber, pageSize, search, params }, { dispatch }) => {
    try {
      const response = await axios.delete(baseUrl() + '/user-projects/' + id)
      toast.success(response?.data?.message)
      await dispatch(
        getUserProjectList({
          pageNumber,
          pageSize,
          search,
          ...params
        })
      )
      return id
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const UserProjectSlice = createSlice({
  name: 'userProject',
  initialState: {
    userProjectsList: [],
    selected: null,
    loader: true,
    currentPage: 1,
    rowsPerPage: 10,
    clearFilter: false,
    filterParams: {},
    loading: false,
    params: {
      pageNumber: 1,
      pageSize: 10,
      search: ''
    },
    total: 0,
    filterData: null
  },
  reducers: {
    selectUserProjects: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setFilterData: (state, action) => {
      if (action.payload === null) {
        state.filterData = null
      } else {
        state.filterData = action.payload
      }
    },
    selectClearFilter: (state, action) => {
      state.clearFilter = action.payload
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setParams: (state, action) => {
      state.params = action.payload
    },
    selectCurrentPage: (state, action) => {
      if (action.payload === null) {
        state.currentPage = null
      } else {
        state.currentPage = action.payload
      }
    },
    selectRowsPerPage: (state, action) => {
      if (action.payload === null) {
        state.rowsPerPage = null
      } else {
        state.rowsPerPage = action.payload
      }
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProjectList.fulfilled, (state, action) => {
        state.userProjectsList = action.payload.userProjectsList
        state.params = action.payload.params
        state.total = action.payload.total
        state.loader = false
      })
      .addCase(getUserProjectFilterList.fulfilled, (state, action) => {
        state.userProjectsList = action.payload.userProjectsList
        state.params = action.payload.params
        state.total = action.payload.total
        state.loader = false
      })
      .addCase(getUserProjectList.pending, (state) => {
        state.loader = true
      })
  }
})

export const {
  selectUserProjects,
  selectClearFilter,
  setLoader,
  selectCurrentPage,
  selectRowsPerPage,
  setParams,
  setLoading,
  setFilterData,
  setUserProjectRowsPerPage
} = UserProjectSlice.actions

export default UserProjectSlice.reducer
