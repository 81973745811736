import { baseUrl } from '../../../app.config'

// ** Auth Endpoints
export default {
  loginEndpoint: baseUrl() + '/auth/login',
  //loginEndpoint: '/jwt/login',
  loginWithOTP: baseUrl() + '/auth/login-with-otp',
  loginWithOTPConfirm: baseUrl() + '/auth/login-with-otp/confirm',
  forgotPassword: baseUrl() + '/auth/forgot-password',
  forgotPasswordConfirm: baseUrl() + '/auth/forgot-password/confirm',
  onBoard: baseUrl() + '/auth/onboard',
  refreshEndpoint: baseUrl() + '/auth/refresh-token',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  setUserName: 'name'
}
