// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { baseUrl } from './../../../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getTopicList = createAsyncThunk(
  'topic/getTopicList',
  async (params) => {
    const response = await axios.get(baseUrl() + '/topics', { params })
    return {
      totalPages: response.data.count.total,
      params: response.data.params,
      topicList: response.data.data,
      filterParams: params
    }
  }
)

export const getAllTopicList = createAsyncThunk(
  'topic/getAllTopicList',
  async (params) => {
    // dispatch(setLoader())
    const response = await axios.get(baseUrl() + '/topics', { params })
    return {
      params: response.data.params,
      AllTopicList: response.data.data
    }
  }
)

export const getTopicById = createAsyncThunk(
  'topic/getTopicById',
  async ({ topicId }) => {
    const response = await axios.get(baseUrl() + `/topics/${topicId}`)
    return {
      topicById: response?.data?.data
    }
  }
)

export const getTopicListByTechnologyId = createAsyncThunk(
  'topic/getTopicListByTechnologyId',
  async ({ id }) => {
    const response = await axios.get(
      baseUrl() + `/topics?export=false&technologyId=${id || +id}`
    )

    return {
      // totalPages: response.data.count.total,
      // params: response.data.params,
      // topicList: response.data.data
      alltopicList: response.data.data
    }
  }
)

export const getTopicListByTopicStatus = createAsyncThunk(
  'topic/getTopicListByTopicStatus',
  async ({ id, userId, status }) => {
    let topics = []
    let url =
      baseUrl() +
      `/topics?export=false&technologyId=${id}&userId=${userId}&topicStatus=${status}`
    await axios
      .get(url)
      .then((res) => {
        topics = [...res?.data]
      })
      .catch((e) => {
        console.log('error while fetching status wise topics', e)
        return {
          alltopicList: []
        }
      })
    return {
      alltopicList: [...topics] || []
    }
  }
)

export const addTopic = createAsyncThunk(
  'topic/addTopic',
  async ({ formData, params }, { dispatch }) => {
    try {
      const res = await axios.post(baseUrl() + '/topics', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      toast.success(res?.data?.message)
      await dispatch(getTopicList(params))
      return true
    } catch (error) {
      toast.error(error?.response?.data?.message)
      return false
    } finally {
      dispatch(setLoading(false))
    }
  }
)
export const topicBulkUpload = createAsyncThunk(
  'topic/topicBulkUpload',
  async ({ formData, params }, { dispatch }) => {
    try {
      const res = await axios.post(
        baseUrl() + '/topics/topicUpload',
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      )
      toast.success(res?.data?.message)
      await dispatch(getTopicList(params))
      return true
      //return topic
    } catch (error) {
      toast.error(error?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const updateTopic = createAsyncThunk(
  'topic/updateTopic',
  async ({ id, formData, params }, { dispatch }) => {
    try {
      const res = await axios.put(baseUrl() + `/topics/${id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      toast.success(res?.data?.message)
      await dispatch(getTopicList(params))
      return { id }
    } catch (error) {
      toast.error(error?.response?.data?.message)
      return false
    } finally {
      dispatch(setLoading(false))
    }
  }
)

export const deleteTopic = createAsyncThunk(
  'topic/deleteTopic',
  async ({ id, pageNo, pageSize, search, params }, { dispatch }) => {
    try {
      dispatch(setLoader())
      const res = await axios.delete(baseUrl() + '/topics/' + id)
      toast.success(res?.data?.message)
      await dispatch(
        getTopicList({
          pageNo,
          pageSize,
          search,
          ...params
        })
      )
      return id
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const topicSlice = createSlice({
  name: 'topic',
  initialState: {
    topicList: [],
    alltopicList: [],
    topicById: [],
    AllTopicList: [],
    selected: null,
    loader: true,
    loading: false,
    currentPage: 1,
    rowsPerPage: 10,
    totalPages: 0,
    clearFilter: false,
    filterParams: {},
    filterData: null
  },
  reducers: {
    selectTopic: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setFilterData: (state, action) => {
      if (action.payload === null) {
        state.filterData = null
      } else {
        state.filterData = action.payload
      }
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    selectCurrentPage: (state, action) => {
      if (action.payload === null) {
        state.currentPage = null
      } else {
        state.currentPage = action.payload
      }
    },
    selectRowsPerPage: (state, action) => {
      if (action.payload === null) {
        state.rowsPerPage = null
      } else {
        state.rowsPerPage = action.payload
      }
    },
    selectClearFilter: (state, action) => {
      state.clearFilter = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTopicList.fulfilled, (state, action) => {
        ;(state.topicList = action.payload.topicList),
          (state.totalPages = action.payload.totalPages)
        state.filterParams = action?.payload?.filterParams
        state.loader = false
      })
      .addCase(getTopicList.pending, (state) => {
        state.loader = true
      })
      .addCase(getTopicListByTechnologyId.fulfilled, (state, action) => {
        state.alltopicList = action.payload.alltopicList
      })
      .addCase(getTopicListByTopicStatus.fulfilled, (state, action) => {
        state.alltopicList = action.payload.alltopicList
      })
      .addCase(getTopicById.fulfilled, (state, action) => {
        state.topicById = action.payload.topicById
      })
      .addCase(getAllTopicList.fulfilled, (state, action) => {
        state.AllTopicList = action.payload.AllTopicList
      })
  }
})

export const {
  selectTopic,
  setLoader,
  selectClearFilter,
  selectCurrentPage,
  selectRowsPerPage,
  setLoading,
  setFilterData
} = topicSlice.actions

export default topicSlice.reducer
