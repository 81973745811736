// ** React Imports
import { lazy } from 'react'

const UserMentor = lazy(() => import('../../views/user-settings/usermentor'))
const UserRole = lazy(() => import('../../views/user-settings/userrole'))
const UserProject = lazy(() => import('../../views/user-settings/user-project'))
const UserTechnology = lazy(() =>
  import('../../views/user-settings/usertechnology')
)

const UserSettingsRoutes = [
  {
    element: <UserMentor />,
    path: '/usersettings/usermentor',
    meta: {
      layout: 'vertical'
    }
  },
  {
    element: <UserProject />,
    path: '/usersettings/user-project',
    meta: {
      layout: 'vertical'
    }
  },
  {
    element: <UserTechnology />,
    path: '/usersettings/usertechnology',
    meta: {
      layout: 'vertical'
    }
  },
  {
    element: <UserRole />,
    path: '/usersettings/userrole',
    meta: {
      layout: 'vertical'
    }
  }
]

export default UserSettingsRoutes
