// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { baseUrl } from './../../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getUserTechnologyList = createAsyncThunk(
  'userTechnology/getUserTechnologyList',
  async (params) => {
    const response = await axios.get(baseUrl() + '/usertechnologies', {
      params
    })
    return {
      totalPages: response.data.totalPages,
      params: response.data.params,
      userTechnologyList: response.data,
      filterParams: params
    }
  }
)

export const addUserTechnology = createAsyncThunk(
  'userTechnology/addUserTechnology',
  async ({ pageNo, pageSize, userId, technolgoyId, params }, { dispatch }) => {
    try {
      const res = await axios.post(
        baseUrl() + '/usertechnologies/assignTechnology',
        {
          userId,
          technolgoyId
        }
      )
      toast.success(res?.data?.message)
      await dispatch(
        getUserTechnologyList({
          pageNo,
          pageSize,
          ...params
        })
      )
    } catch (error) {
      toast.error(error?.response?.data?.message)
    } finally {
      dispatch(setLoading(false))
    }
    // return userTechnology
  }
)

export const updateUserTechnology = createAsyncThunk(
  'userTechnology/updateAssignTopic',
  async (
    { userId, technolgoyId, pageNo, pageSize, search, params },
    { dispatch }
  ) => {
    try {
      const res = await axios.post(baseUrl() + `/usertechnologies`, {
        userId,
        technolgoyId
      })

      toast.success(res?.data?.message)
      await dispatch(
        getUserTechnologyList({
          pageNo,
          pageSize,
          search,
          ...params
        })
      )
      // return { id }
    } catch (error) {
      toast.error(error?.response?.data?.message)
    } finally {
      dispatch(setLoading(false))
    }
  }
)

export const deleteUserTechnology = createAsyncThunk(
  'userTechnology/deleteAssignTopic',
  async ({ id, pageNo, pageSize }, { dispatch }) => {
    try {
      const res = await axios.delete(baseUrl() + '/usertechnologies/' + id)
      toast.success(res?.data?.message)
      await dispatch(
        getUserTechnologyList({
          pageNo,
          pageSize
        })
      )
      return id
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const UserTechSlice = createSlice({
  name: 'userTechnology',
  initialState: {
    userTechnologyList: [],
    selected: null,
    loader: true,
    loading: false,
    filterParams: {},
    clearFilter: false,
    filterData: null
  },
  reducers: {
    selectUserTech: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setFilterData: (state, action) => {
      if (action.payload === null) {
        state.filterData = null
      } else {
        state.filterData = action.payload
      }
    },
    selectClearFilter: (state, action) => {
      state.clearFilter = action.payload
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserTechnologyList.fulfilled, (state, action) => {
        state.userTechnologyList = action.payload.userTechnologyList
        state.loader = false
        state.filterParams = action?.payload?.filterParams
      })
      .addCase(getUserTechnologyList.pending, (state) => {
        state.loader = true
      })
  }
})

export const {
  selectUserTech,
  setFilterData,
  selectClearFilter,
  setLoader,
  setLoading
} = UserTechSlice.actions

export default UserTechSlice.reducer
