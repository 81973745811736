// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl } from './../../../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getBranchList = createAsyncThunk(
  'branch/getBranchList',
  async (params) => {
    const response = await axios.get(baseUrl() + '/branch', { params })
    return {
      totalPages: response.data.count.total,
      branchList: response.data.data,
    }
  }
)

export const getAllBranch = createAsyncThunk(
  'branch/getAllBranch',
  async () => {
    const response = await axios.get(baseUrl() + '/branch')
    return {
      totalPages: response.data.count.total,
      allBranchList: response.data.data,
    }
  }
)

export const addBranch = createAsyncThunk(
  'branch/addBranch',
  async (branch, { dispatch }) => {
    try {
      const res = await axios.post(baseUrl() + '/branch', branch)
      toast.success(res?.data?.message)
      return branch
    } catch (error) {
      toast.error(error?.response?.data?.message)
      return error
    } finally {
      dispatch(setLoading(false))
    }
  }
)

export const branchSlice = createSlice({
  name: 'branch',
  initialState: {
    branchList: [],
    loader: false,
    totalPages: 0,
    show: false,
    loading: false,
    allBranchList: []
  },
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload || false
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setShow: (state, action) => {
      state.show = action.payload || false
    }
  },
  extraReducers: {
    [getBranchList.pending] : (state) => {
      state.loader = true
    },
    [getBranchList.fulfilled]: (state, action) => {
      state.branchList = action.payload.branchList
      state.totalPages = action.payload.totalPages
      state.loader = false
    },
    [getBranchList.rejected]: (state) => {
      state.loader = false
    },
    [addBranch.fulfilled]: (state) => {
      state.show = false
    },
    [getAllBranch.fulfilled]: (state, action) => {
      state.allBranchList = action.payload.allBranchList
    }
  }
})

export const { setLoader, setShow, setLoading } = branchSlice.actions

export default branchSlice.reducer
