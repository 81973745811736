import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { baseUrl } from '../../../../../app.config'
import axios from 'axios'
import toast from 'react-hot-toast'

export const getAllFilterData = createAsyncThunk(
  'get/getAllFilterData',
  async (id) => {
    const response = await axios.get(
      baseUrl() + '/usertechnologies/usersTech/' + id
    )
    return {
      allFilterData: response?.data?.data ? response?.data?.data : []
    }
  }
)

export const getAllMailConfigurations = createAsyncThunk(
  'get/getAllMailConfigurations',
  async () => {
    const response = await axios.get(baseUrl() + '/mailconfiguration')
    return {
      allMail: response?.data?.data ? response?.data?.data : []
    }
  }
)

export const sendReviewCallInvite = createAsyncThunk(
  'add/sendReviewCallInvite',
  async (params, { dispatch }) => {
    dispatch(setSubmitLoader({ loading: true }))
    try {
      const res = await axios.post(
        baseUrl() + '/mailconfiguration/reviewCall-invite',
        params
      )
      toast.success(res?.data?.message)
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.response?.data?.errorMessage
      )
    }
    dispatch(setSubmitLoader({ loading: false }))
  }
)

export const addMailConfigurationsOfReview = createAsyncThunk(
  'add/saveMailConfigurationsOfReview',
  async (params, { dispatch }) => {
    dispatch(setAddLoader(true))
    try {
      const res = await axios.post(baseUrl() + '/mailconfiguration', params)
      dispatch(getAllMailConfigurations())
      toast.success(res?.data?.message)
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.response?.data?.errorMessage
      )
    }
    dispatch(setAddLoader(false))
  }
)

export const updateconfiguration = createAsyncThunk(
  'update/mailConfigurationOfReview',
  async ({ params, id }, { dispatch }) => {
    params.mailType === 'REVIEWMAIL'
      ? dispatch(setSubmitLoader({ loading: true, id: id }))
      : dispatch(setSubmitLoader({ loading: true, id: null }))
    try {
      const res = await axios.put(
        baseUrl() + `/mailconfiguration/${id}`,
        params
      )
      dispatch(getAllMailConfigurations())
      toast.success(res?.data?.message)
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.response?.data?.errorMessage
      )
    }
    params.mailType
      ? dispatch(setSubmitLoader({ loading: false, id: id }))
      : dispatch(setSubmitLoader({ loading: false, id: null }))
  }
)

export const deleteMailConfiguration = createAsyncThunk(
  'delete/mailconfiguration',
  async (id, { dispatch }) => {
    try {
      const res = await axios.delete(baseUrl() + `/mailconfiguration/${id}`)
      dispatch(getAllMailConfigurations())
      toast.success(res?.data?.message)
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.response?.data?.errorMessage
      )
    }
  }
)

export const mailconfigurationSlice = createSlice({
  name: 'mailConfiguration',
  initialState: {
    allMail: [],
    allFilterData: [],
    isLoading: false,
    addLoader: false,
    submitLoader: { loading: false, id: null }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllMailConfigurations.fulfilled, (state, action) => {
        state.allMail = action.payload.allMail
        state.isLoading = false
      })
      .addCase(getAllMailConfigurations.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getAllMailConfigurations.rejected, (state, action) => {
        state.isLoading = false
      })
      .addCase(getAllFilterData.fulfilled, (state, action) => {
        state.allFilterData = action.payload.allFilterData
        state.isLoading = false
      })
      .addCase(getAllFilterData.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getAllFilterData.rejected, (state, action) => {
        state.isLoading = false
        state.allFilterData = []
      })
  },
  reducers: {
    setAddLoader: (state, action) => {
      state.addLoader = action.payload
    },
    setSubmitLoader: (state, action) => {
      ;(state.submitLoader.loading = action.payload.loading),
        (state.submitLoader.id = action.payload.id)
    },
    setAllFilterData: (state) => {
      state.isLoading = true
      state.allFilterData = []
      state.isLoading = false
    }
  }
})

export const { setAddLoader, setSubmitLoader, setAllFilterData } =
  mailconfigurationSlice.actions

export default mailconfigurationSlice.reducer
