// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl } from '../../../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getModuleDropdown = createAsyncThunk(
  'masterModule/getModuleDropdown',
  async () => {
    const response = await axios.get(baseUrl() + '/modules')
    return {
      moduleDropdown: response.data.data
    }
  }
)

export const getModuleList = createAsyncThunk(
  'masterModule/getModuleList',
  async (params) => {
    // dispatch(setLoader())
    const response = await axios.get(baseUrl() + '/modules', { params })
    return {
      totalPages: response.data.count.total,
      moduleList: response.data.data,
      loader: false
    }
  }
)

export const addModule = createAsyncThunk(
  'masterModule/addModule',
  async (params, {dispatch}) => {
    try {
      const res = await axios.post(baseUrl() + '/modules', params)
      toast.success(res?.data?.message)
      return true
    } catch (error) {
      toast.error(error?.response?.data?.message)
      return false
    } finally {
      dispatch(setLoading(false))
    }
  }
)

export const updateModule = createAsyncThunk(
  'masterModule/updateModule',
  async ({ id, moduleName}, { dispatch }) => {
    try {
      const res = await axios.put(baseUrl() + `/modules/${id}`, {
        id,
        moduleName
      })
      toast.success(res?.data?.message)
      return { id, moduleName }
    } catch (error) {
      toast.error(error?.response?.data?.message)
      return false
    } finally {
      dispatch(setLoading(false))
    }
  }
)

export const deleteModule = createAsyncThunk(
  'masterModule/deleteModule',
  async ({ id }, { dispatch }) => {
    try {
      dispatch(setLoader())
      const res = await axios.delete(baseUrl() + `/modules/${id}`)
        toast.success(res?.data?.message)
      return id
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const masterModule = createSlice({
  name: 'masterModule',
  initialState: {
    moduleDropdown: [],
    moduleList: [],
    selectMasterModule: [],
    loader: false,
    loading:false,
    rowsPerPage: 10,
    currentPage: 1,
    totalPages: 0
  },
  reducers: {
    selectModule: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setLoader: (state) => {
      state.loader = !state.loader
    },
    setLoading:(state, action) => {
      state.loading = action.payload
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    }
  },

  extraReducers: {
    [getModuleDropdown.fulfilled]: (state, action) => {
      state.moduleDropdown = action.payload.moduleDropdown
    },
    [getModuleList.fulfilled]: (state, action) => {
      state.moduleList = action.payload.moduleList
      state.totalPages = action.payload.totalPages
      state.loader = action.payload.loader
    },
    [getModuleList.pending]: (state, action) => {
      state.loader = true
    }
  }
})

export const { selectModule, setLoader, setRowsPerPage, setCurrentPage, setLoading } =
  masterModule.actions

export default masterModule.reducer
