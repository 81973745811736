// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { baseUrl } from './../../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getUserRoleList = createAsyncThunk(
  'userRole/getUserRoleList',
  async (params) => {
    const response = await axios.get(baseUrl() + '/user_role', {
      params
    })
    return {
      totalPages: response.data?.count?.total,
      params: response.data.params,
      userRoleList: response.data?.data
    }
  }
)

export const addUserRole = createAsyncThunk(
  'userRole/addUserRole',
  async ({ pageNo, pageSize, userId, roleId }, { dispatch }) => {
    try {
      const res = await axios.post(baseUrl() + '/user_role', { userId, roleId })
      toast.success(res?.data?.message)
      await dispatch(
        getUserRoleList({
          pageNo,
          pageSize
        })
      )
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const updateUserRole = createAsyncThunk(
  'userRole/updateAssignTopic',
  async ({ userId, roleId, pageNo, pageSize, search }, { dispatch }) => {
    try {
      const res = await axios.post(baseUrl() + `/usertechnologies`, {
        userId,
        roleId
      })
      toast.success(res?.data?.message)
      await dispatch(
        getUserRoleList({
          pageNo,
          pageSize,
          search
        })
      )
      //return { id }
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const deleteUserRole = createAsyncThunk(
  'userRole/deleteAssignTopic',
  async ({ id, pageNo, pageSize }, { dispatch }) => {
    await axios.delete(baseUrl() + '/usertechnologies/' + id)
    await dispatch(
      getUserRoleList({
        pageNo,
        pageSize
      })
    )
    return id
  }
)

export const UserRoleSlice = createSlice({
  name: 'userRole',
  initialState: {
    userRoleList: [],
    selected: null,
    rowsPerPage: 10,
    currentPage: 1,
    totalPages: 0,
    loader: true
  },
  reducers: {
    selectUserRole: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    selectCurrentPage: (state, action) => {
      if (action.payload === null) {
        state.currentPage = null
      } else {
        state.currentPage = action.payload
      }
    },
    selectRowsPerPage: (state, action) => {
      if (action.payload === null) {
        state.rowsPerPage = null
      } else {
        state.rowsPerPage = action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserRoleList.fulfilled, (state, action) => {
        state.userRoleList = action.payload.userRoleList
        state.totalPages = action.payload.totalPages
        state.loader = false
      })
      .addCase(getUserRoleList.pending, (state) => {
        state.loader = true
      })
  }
})

export const { selectUserRole, selectCurrentPage, selectRowsPerPage } =
  UserRoleSlice.actions

export default UserRoleSlice.reducer
