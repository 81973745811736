import classNames from 'classnames'
import React, { useEffect, useState, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import toast from 'react-hot-toast'

//**Utils */
import { selectThemeColors } from '@utils'

import { Controller, useForm } from 'react-hook-form'

import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap'
import Select from 'react-select'
import classnames from 'classnames'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import { getModuleDropdown } from '../../apps/master/module/store'
import { uploadUsers } from './store'
import { memo } from 'react'

const defaultValues = {
  module: [],
  file: null
}
const UploadForm = ({ show, setShow }) => {
  const [fileUpload, setFile] = useState('')
  const moduleListValue = useSelector((state) => state?.masterModule)

  const dispatch = useDispatch()

  const {
    reset,
    control,
    setError,
    setValue,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues })

  const handleDiscard = () => {
    reset()
    setValue('file', null)
    setShow(false)
  }

  const handleModalClosed = () => {
    reset()
    setValue('file', null)
  }

  const handleFile = (e) => {
    setValue('file', e.target.files[0])
    setFile(e.target?.files[0]?.name)
  }

  const onSubmit = (data) => {
    let formData = new FormData()
    formData.append('file', data.file)
    dispatch(
      uploadUsers({
        payload: formData,
        params: { moduleName: data?.module?.label }
      })
    )

    handleDiscard()
  }

  const moduleOptions = moduleListValue?.moduleDropdown
    ?.filter((temp) => {
      return ['User', 'Topic Master'].includes(temp.moduleName)
    })
    .map((module) => {
      return {
        label: module.moduleName,
        value: module.id
      }
    })
  useEffect(() => {
    dispatch(getModuleDropdown())
  }, [])

  return (
    <>
      <Modal
        isOpen={show}
        onClosed={handleModalClosed}
        toggle={() => setShow(!show)}
        className="modal-dialog-centered"
        // backdrop="static"
        // keyboard={false}
      >
        <ModalHeader
          className="bg-transparent "
          toggle={() => setShow(!show)}
        ></ModalHeader>
        <ModalBody>
          <div className="text-center mb-0">
            <h1 className="mb-0">Bulk Upload</h1>
          </div>

          <Row tag={Form} className="p-2" onSubmit={handleSubmit(onSubmit)}>
            <Col xs={12} display="flex">
              <div className="mb-1">
                <Label className="form-label" for="module">
                  Module <span className="text-danger">*</span>
                </Label>
                <Controller
                  name="module"
                  control={control}
                  {...register('module', {
                    required: 'Please Select Modules Name'
                  })}
                  render={({ field }) => (
                    <Select
                      id="module"
                      isClearable={false}
                      classNamePrefix="select"
                      options={moduleOptions}
                      theme={selectThemeColors}
                      {...field}
                      invalid={errors.module && true}
                    />
                  )}
                />
              </div>
              <Col className="mb-1">
                <Label className="form-label" for="file">
                  Upload File <span className="text-danger">*</span>
                </Label>

                <Controller
                  name="file"
                  control={control}
                  {...register('file', {
                    required: 'Please Select Modules Name'
                  })}
                  render={({ field }) => (
                    <Input
                      id="file"
                      type="file"
                      name="file"
                      {...register('file', {
                        required: 'Please upload a file'
                      })}
                      invalid={errors.file && true}
                      onChange={handleFile}
                      files={fileUpload}
                    />
                  )}
                />
              </Col>
            </Col>

            <Col xs={12} className="text-center mt-2 mb-1">
              <Button className="me-1" color="primary" type="submit">
                Upload
              </Button>
              <Button outline type="reset" onClick={handleDiscard}>
                Discard
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  )
}

export default memo(UploadForm)
