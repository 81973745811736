import TextWithEllipsis from '../../../../@core/components/repeater/text-with-ellipsis/TextWithEllipsis'

export const columns = [
  {
    sortable: false,
    width: '35%',
    name: 'Mentor Name',
    cell: ({ mentorName }) => (mentorName ? mentorName : '--'),
    selector: (row) => row?.mentorName
  },

  {
    sortable: false,
    width: '50%',
    name: 'Technology Name',
    cell: ({ technologyName }) =>
      technologyName ? (
        <TextWithEllipsis string={technologyName} maxLength={40} />
      ) : (
        '--'
      ),
    selector: (row) => row?.technologyName
  }
]
