// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import permissions from '@src/views/apps/roles-permissions/store'
import topic from '../views/apps/master/topic/store'
import technology from '../views/apps/master/technology/store'
import category from '../views/apps/master/category/store'
import roles from '../views/apps/roles-permissions/roles/rolesStore'
import subTopic from '../views/apps/master/subtopic/store'
import task from '../views/apps/master/task/store'
import session from '../views/apps/Sessions/store'
import masterModule from '../views/apps/master/module/store'
import assignTopic from '../views/apps/task&topics/assigntopic/store'
import assignTask from '../views/apps/task&topics/assigntask/store'
import userMentor from '../views/user-settings/usermentor/store'
import userTechnology from '../views/user-settings/usertechnology/store'
import user from '../views/apps/master/user/store'
import projectMaster from '../views/apps/Project-Mapping/projectMaster/store'
import userProject from '../views/user-settings/user-project/store'
import userRole from '../views/user-settings/userrole/store'
import dashboard from '../views/dashboard/store'
import bulkupload from '../views/pages/bulk-upload/store'
import project from '../views/apps/master/project/store'
import mentorMaster from '../views/apps/master/mentorMaster/store'
import branchMaster from "../views/apps/master/branch/store"
import designationMaster from "../views/apps/master/designation/store"
import reviewNotDoneReport from "../views/apps/reports/reviewsNotDone/store"
import emailConfiguration from '../views/apps/roles-permissions/emailConfigurations/store'

const rootReducer = {
  auth,
  navbar,
  layout,
  permissions,
  roles,
  masterModule,
  task,
  topic,
  technology,
  category,
  subTopic,
  assignTopic,
  assignTask,
  userMentor,
  userTechnology,
  user,
  session,
  dashboard,
  projectMaster,
  userProject,
  userRole,
  bulkupload,
  project,
  mentorMaster,
  branchMaster,
  designationMaster,
  emailConfiguration,
  reviewNotDoneReport
}

export default rootReducer
