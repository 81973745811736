// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../../../app.config'

export const getProjectList = createAsyncThunk(
  'project/getProjectList',
  async (params) => {
    const response = await axios.get(baseUrl() + '/projects', {
      params
    })
    return {
      totalPages: response.data.count,
      params: response.data.params,
      userProjectsList: response.data,
      filterParams: params
    }
  }
)

export const addProjects = createAsyncThunk(
  'project/addProjects',
  async ({ formData, params }, { dispatch }) => {
    try {
      const response = await axios.post(baseUrl() + '/projects', formData)
      toast.success(response?.data?.message)
      await dispatch(getProjectList(params))
    } catch (error) {
      toast.error(error?.response?.data?.message)
    } finally {
      dispatch(setLoading(false))
    }

    // return projects
  }
)

export const updateProjects = createAsyncThunk(
  'project/updateProjects',
  async ({ formData, params }, { dispatch }) => {
    try {
      const response = await axios.put(
        baseUrl() + `/projects/${formData.id}`,
        formData
      )
      toast.success(response?.data?.message)
      await dispatch(getProjectList(params))
    } catch (error) {
      toast.error(error?.response?.data?.message)
    } finally {
      dispatch(setLoading(false))
    }
  }
)

export const deleteProjects = createAsyncThunk(
  'project/deleteAssignTopic',
  async ({ id, pageNo, pageSize, search }, { dispatch, ...rest }) => {
    try {
      dispatch(setLoader(true))
      const response = await axios.delete(baseUrl() + '/projects/' + id)
      const temp = rest?.getState()?.project?.filterData
      toast.success(response?.data?.message)
      await dispatch(
        getProjectList({
          pageNo,
          pageSize,
          search,
          projectId: rest?.getState()?.project?.filterData?.projectId?.value,
          technologyId:
            rest?.getState()?.project?.filterData?.technologyId?.value
        })
      )
      return id
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const UserTechSlice = createSlice({
  name: 'project',
  initialState: {
    userProjectsList: [],
    selected: null,
    loader: true,
    totalPages: 0,
    currentPage: 1,
    rowsPerPage: 10,
    clearFilter: false,
    loading: false,
    filterParams: {},
    filterData: null
    // params: {
    //   pageNo: 1,
    //   pageSize: 10
    // },
  },
  reducers: {
    selectProjects: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setFilterData: (state, action) => {
      if (action.payload === null) {
        state.filterData = null
      } else {
        state.filterData = action.payload
      }
    },
    selectClearFilter: (state, action) => {
      state.clearFilter = action.payload
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    selectCurrentPage: (state, action) => {
      if (action.payload === null) {
        state.currentPage = null
      } else {
        state.currentPage = action.payload
      }
    },
    selectRowsPerPage: (state, action) => {
      if (action.payload === null) {
        state.rowsPerPage = null
      } else {
        state.rowsPerPage = action.payload
      }
    }
    // setParams: (state, action) => {
    //   state.params = {
    //     ...state.params,
    //     ...action.payload
    //   }
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProjectList.fulfilled, (state, action) => {
        state.userProjectsList = action.payload.userProjectsList
        state.totalPages = action.payload.totalPages
        state.filterParams = action?.payload?.filterParams
        state.loader = false
      })
      .addCase(getProjectList.pending, (state) => {
        state.loader = true
      })
  }
})

export const {
  selectProjects,
  selectClearFilter,
  setLoader,
  selectCurrentPage,
  selectRowsPerPage,
  setLoading,
  setFilterData
} = UserTechSlice.actions

export default UserTechSlice.reducer
