// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl } from '../../../app.config'

// ** Axios Imports
import axios from 'axios'
import { initialState } from 'react-doc-viewer/build/state/reducer'
import toast from 'react-hot-toast'

export const getReviews = createAsyncThunk(
  'dashboard/getReviews',
  async (params) => {
    const response = await axios.get(baseUrl() + '/user-review/ratingsList', {
      params
    })
    return {
      data: response.data.data ? response.data.data : []
    }
  }
)

export const getMaxTopics = createAsyncThunk(
  'dashboard/getMaxTopics',
  async (params) => {
    const response = await axios.get(
      baseUrl() + '/user-topic/max-topics-covered-by-user',
      { params }
    )
    return {
      data: response.data.data ? response.data.data : []
    }
  }
)

export const maxTopicsCoveredTech = createAsyncThunk(
  'dashboard/maxTopicsCoveredTech',
  async (params) => {
    const response = await axios.get(
      baseUrl() + '/technologies/maxTopicCovered',
      { params }
    )
    return {
      data: response.data.data ? response.data.data : []
    }
  }
)

export const topResourcesTech = createAsyncThunk(
  'dashboard/topResourcesTech',
  async (params) => {
    const response = await axios.get(
      baseUrl() + '/user/get-top-user-ready-to-deploy',
      { params }
    )
    return {
      data: response.data.data ? response.data.data : []
    }
  }
)

export const maxReviewMentor = createAsyncThunk(
  'dashboard/maxReviewMentor',
  async (params) => {
    const response = await axios.get(
      baseUrl() + '/dashboard/reviewCountOfMentors',
      { params }
    )
    return {
      data: response.data.data ? response.data.data : []
    }
  }
)
export const getUserStatusCount = createAsyncThunk(
  'dashboard/getUserStatusCount',
  async (params) => {
    const response = await axios.get(
      baseUrl() + `/dashboard/usersStatusCount`,
      { params }
    )
    return {
      data: response.data.data ? response.data.data : []
    }
  }
)

export const getUserCountByTechnology = createAsyncThunk(
  'dashboard/getUserCountByTechnology',
  async (params) => {
    const response = await axios.get(
      baseUrl() + '/dashboard/userCountByTechnology',
      { params }
    )
    return {
      data: response.data.data ? response.data.data : []
    }
  }
)

export const getAllUsersCountByStatus = createAsyncThunk(
  'dashboard/getAllUsersCountByStatus',
  async (params) => {
    const response = await axios.get(
      baseUrl() + '/dashboard/usersCountByStatus',
      { params }
    )
    return {
      data: response.data.data ? response.data.data : []
    }
  }
)

export const getUserCountTechStatus = createAsyncThunk(
  'dashboard/getUserCountTechStatus',
  async (params) => {
    const response = await axios.get(
      baseUrl() + '/dashboard/userCountTechStatus',
      { params }
    )
    return {
      data: response.data.data ? response.data.data : []
    }
  }
)

export const getUserReviewDays = createAsyncThunk(
  'dashboard/getUserReviewDays',
  async (params) => {
    try {
      const response = await axios.get(
        baseUrl() + '/dashboard/daysFromLastReview',
        { params }
      )

      return {
        data: response?.data?.data,
        totalPages: response?.data?.count?.total,
        currentPage: response?.data?.count?.pageNumber,
        rowsPerPage: response?.data?.count?.pageSize,
        params: { ...params }
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.response?.data?.errorMessage
      )
    }
  }
)

export const getMentorReviewList = createAsyncThunk(
  'user-mentor/reviewList',
  async (id) => {
    try {
      const response = await axios.get(
        baseUrl() + `/user-mentor/reviewList/${id}`
      )
      return {
        data: response?.data?.data ? response?.data?.data : []
      }
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const dashboard = createSlice({
  name: 'dashboard',
  initialState: {
    userCountByTechnology: [],
    userCountByStatus: [],
    userCountTechStatus: [],
    daysFromLastReview: [],
    resourceList: [],
    userStatusCount: [],
    mentorReviewList: [],
    maxCountMentor: [],
    currentPage: 1,
    rowsPerPage: 10,
    totalPages: 0,
    reload: false,
    status: null,
    clearFilter: false,
    select: null,
    loader: true,
    reviewTableLoader: true,
    params: {}
  },
  reducers: {
    selectClearFilter: (state) => {
      state.clearFilter = !state.clearFilter
    },
    setLoader: (state) => {
      state.loader = !state.loader
    },
    clearMentorReviewList: (state) => {
      state.mentorReviewList = []
    },
    setRevNtRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload
    }
  },

  extraReducers: {
    [getUserCountByTechnology.fulfilled]: (state, action) => {
      state.userCountByTechnology = action.payload.data
    },
    [getAllUsersCountByStatus.fulfilled]: (state, action) => {
      state.userCountByStatus = action.payload.data
    },
    [getUserCountTechStatus.fulfilled]: (state, action) => {
      state.userCountTechStatus = action.payload.data
    },
    [getUserReviewDays.fulfilled]: (state, action) => {
      ;(state.daysFromLastReview = action?.payload?.data),
        (state.loader = false),
        (state.totalPages = action?.payload?.totalPages),
        (state.params = action?.payload?.params),
        (state.currentPage = action?.payload?.currentPage),
        (state.rowsPerPage = action?.payload?.rowsPerPage)
    },
    [getUserReviewDays.pending]: (state) => {
      state.loader = true
    },
    [getUserReviewDays.rejected]: (state) => {
      state.loader = false
    },
    [getReviews.fulfilled]: (state, action) => {
      state.resourceList = action.payload.data
      state.loader = false
    },
    [getMaxTopics.fulfilled]: (state, action) => {
      state.resourceList = action.payload.data
      state.loader = false
    },
    [maxTopicsCoveredTech.fulfilled]: (state, action) => {
      state.resourceList = action.payload.data
      state.loader = false
    },
    [topResourcesTech.fulfilled]: (state, action) => {
      state.resourceList = action.payload.data
      state.loader = false
    },
    [maxReviewMentor.fulfilled]: (state, action) => {
      state.resourceList = action.payload.data
      state.loader = false
    },
    [getUserStatusCount.fulfilled]: (state, action) => {
      state.userStatusCount = action.payload.data
      state.loader = false
    },
    [getMentorReviewList.fulfilled]: (state, action) => {
      state.mentorReviewList = action?.payload?.data
      state.reviewTableLoader = false
    },
    [getMentorReviewList.pending]: (state) => {
      state.reviewTableLoader = true
    },
    [getMentorReviewList.rejected]: (state) => {
      state.reviewTableLoader = false
    }
  }
})

export const {
  selectDashboard,
  selectClearFilter,
  setLoader,
  clearMentorReviewList,
  setRevNtRowsPerPage
} = dashboard.actions

export default dashboard.reducer
