// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl } from '../../../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getCategoryList = createAsyncThunk(
  'category/getCategoryList',
  async (params) => {
    const response = await axios.get(baseUrl() + '/category', { params })
    return {
      totalPages: response.data.count.total,
      params: response.data.params,
      data: response.data.data
    }
  }
)

export const allCategoryList = createAsyncThunk(
  'category/allCategoryList',
  async (params) => {
    const response = await axios.get(baseUrl() + '/category', { params })
    return {
      params: response.data.params,
      allCategoryList: response.data.data
    }
  }
)

export const addCategory = createAsyncThunk(
  'category/addCategory',
  async (category, { dispatch }) => {
    try {
      const res = await axios.post(baseUrl() + '/category', category)
      toast.success(res?.data?.message)
      //await dispatch(getCategoryList())
      return category
    } catch (error) {
      toast.error(error?.response?.data?.message)
      return false
    } finally {
      dispatch(setLoading(false))
    }
  }
)

export const updateCategory = createAsyncThunk(
  'category/updateCategory',
  async ({ id, categoryName }, { dispatch, getState }) => {
    try {
      const res = await axios.put(baseUrl() + `/category/${id}`, {
        id,
        categoryName
      })
      toast.success(res?.data?.message)
      await dispatch(getCategoryList())
      return { id, categoryName }
    } catch (error) {
      toast.error(error?.response?.data?.message)
    } finally {
      dispatch(setLoading(false))
    }
  }
)

export const deleteCategory = createAsyncThunk(
  'category/deleteCategory',
  async ({ id }, { dispatch, getState }) => {
    try {
      const res = await axios.delete(baseUrl() + `/category/${id}`)
      toast.success(res?.data?.message)
      await dispatch(getCategoryList())
      return id
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const category = createSlice({
  name: 'category',
  initialState: {
    categoryList: [],
    selectCategory: [],
    allCategoryList: [],
    reload: false,
    loading: false,
    totalPages: 0,
    selected: null
  },
  reducers: {
    selectCat: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    }
  },

  extraReducers: {
    [addCategory.fulfilled]: (state, action) => {
      state.reload = !state.reload
    },
    [getCategoryList.fulfilled]: (state, action) => {
      state.selectCategory = action.payload
      state.totalPages = action.payload.totalPages
    },
    [allCategoryList.fulfilled]: (state, action) => {
      state.allCategoryList = action.payload.allCategoryList
    },
    [updateCategory.fulfilled]: (state, action) => {
      state.reload = !state.reload
    },
    [deleteCategory.fulfilled]: (state, action) => {
      state.reload = !state.reload
    }
  }
})

export const { selectCat, setLoading } = category.actions

export default category.reducer
