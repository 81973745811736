// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { baseUrl } from './../../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getAllMentorList = createAsyncThunk(
  'userMentor/getAllMentorList',
  async (params) => {
    const response = await axios.get(
      baseUrl() + '/user/adminMentorList/all',
      {
        params
      }
    )
    return {
      params: response.data.count,
      mentorsList: response.data.data
    }
  }
)
export const getAllMentor = createAsyncThunk(
  'userMentor/getAllMentor',
  async (params) => {
    const response = await axios.get(
      baseUrl() + '/user-mentor/mentorsList/all',
      {
        params
      }
    )
    return {
      params: response.data.params,
      mentorsListData: response.data.data
    }
  }
)

export const getMentorsByUserId = createAsyncThunk(
  'userMentor/getMentorsByUserId',
  async ({ id }) => {
    const response = await axios.get(baseUrl() + '/user-mentor/' + id)
    return {
      mentorsListByUserId: response.data.data
    }
  }
)

export const getAllUserMentorList = createAsyncThunk(
  'userMentor/getAllUserMentorList',
  async (params) => {
    const response = await axios.get(baseUrl() + '/user-mentor', { params })
    return {
      totalPages: response?.data?.count?.total,
      params: { ...params },
      userMentorList: response.data.data
    }
  }
)

export const addMentorToUser = createAsyncThunk(
  'userMentor/addMentorToUser',
  async ({ mentorId, userId, pageNo, pageSize, params }, { dispatch }) => {
    try {
      const res = await axios.post(baseUrl() + '/user-mentor', { mentorId, userId })
      toast.success(res?.data?.message)
      dispatch(
        getAllUserMentorList({
          pageNo,
          pageSize,
          name: 'MENTOR',
          ...params
        })
      )
      //return assignTopic
    } catch (error) {
      toast.error(error?.response?.data?.message)
      dispatch(
        getAllUserMentorList({
          pageNo,
          pageSize,
          name: 'MENTOR',
          ...params
        })
      )
    } finally {
      dispatch(setLoading(false))
    }
  }
)

export const updateMentorForUser = createAsyncThunk(
  'userMentor/updateMentorForUser',
  async (
    { mentorId, userId, pageNo, pageSize, search, params },
    { dispatch, getState }
  ) => {
    try {
      const res = await axios.put(baseUrl() + '/user-mentor', { mentorId, userId })
      toast.success(res?.data?.message)
      dispatch(
        getAllUserMentorList({
          pageNo,
          pageSize,
          search,
          ...params
        })
      )
      return true
    } catch (error) {
      toast.error(error?.response?.data?.message)
      return false
    } finally {
      dispatch(setLoading(false))
    }
  }
)

export const deleteUserMentor = createAsyncThunk(
  'userMentor/deleteUserMentor',
  async ({ id, pageNo, pageSize }, { dispatch }) => {
    try {
      const res = await axios.delete(baseUrl() + '/user-mentor/' + id)
      toast.success(res?.data?.message)
      await dispatch(
        getAllUserMentorList({
          pageNo,
          pageSize
        })
      )
      return id
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const UserMentorSlice = createSlice({
  name: 'userMentor',
  initialState: {
    mentorsList: [],
    userMentorList: [],
    mentorsListByUserId: [],
    mentorsListData: [],
    loader: true,
    totalPages: 0,
    selected: null,
    loading: false,
    params: {
      pageNo: 1,
      pageSize: 10
    },
    selectMentor: {},
    clearFilter: false,
    filterData: null,
  },
  reducers: {
    selectUserMentor: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setFilterData: (state, action) => {
      if (action.payload === null) {
        state.filterData = null
      } else {
        state.filterData = action.payload
      }
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setSelectMentor: (state, action) => {
      if (action.payload === null) {
        state.selectMentor = null
      } else {
        state.selectMentor = action.payload
      }
    },
    setParams: (state, action) => {
      state.params = {
        ...state.params,
        ...action.payload
      }
    },
    selectClearFilter: (state, action) => {
      state.clearFilter = action.payload
    },
    setLoading:(state, action) => {
      state.loading = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUserMentorList.fulfilled, (state, action) => {
        state.userMentorList = action.payload.userMentorList
        state.totalPages = action.payload.totalPages
        state.params = action.payload.params
        state.loader = false
      })
      .addCase(getAllMentorList.fulfilled, (state, action) => {
        state.mentorsList = action.payload.mentorsList
        state.loader = false
      })
      .addCase(getMentorsByUserId.fulfilled, (state, action) => {
        state.mentorsListByUserId = action.payload.mentorsListByUserId
      })
      .addCase(getAllMentor.fulfilled, (state, action) => {
        state.mentorsListData = action.payload.mentorsListData
      })
  }
})

export const {
  selectUserMentor,
  setLoader,
  setSelectMentor,
  setParams,
  selectClearFilter,
  setLoading,
  setFilterData
} = UserMentorSlice.actions

export default UserMentorSlice.reducer
