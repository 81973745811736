export const columns = [
  {
    sortable: false,
    minWidth: '200px',
    name: 'File',
    cell: ({ fileName }) => (fileName ? fileName : '--'),
    selector: (row) => row?.fileName
  },
  {
    sortable: false,
    minWidth: '200px',
    name: 'Uploaded By ',
    cell: ({ uploadedBy }) => (uploadedBy ? uploadedBy : '--'),
    selector: (row) => row?.uploadedBy
  },
  {
    sortable: false,
    minWidth: '200px',
    name: 'Uploaded At',
    cell: ({ uploadedAt }) => (uploadedAt ? uploadedAt : '--'),
    selector: (row) => row?.uploadedAt
  },
  {
    sortable: false,
    minWidth: '200px',
    name: 'Module',
    cell: ({ moduleName }) => (moduleName ? moduleName : '--'),
    selector: (row) => row?.moduleName
  },
  {
    sortable: false,
    minWidth: '200px',
    name: 'Status',
    cell: ({ status }) => (status ? status : '--'),
    selector: (row) => row?.status
  }
]
