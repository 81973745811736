// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../../../app.config'

export const getRolesData = createAsyncThunk(
  'roles/getRolesData',
  async (params, { dispatch }) => {
    // dispatch(setLoader())
    const response = await axios.get(baseUrl() + '/roles', {
      params
    })
    return {
      totalPages: response.data.count.total,
      params: response.data.params,
      roles: response.data.data,
      loader: false
    }
  }
)

export const getRolesDataById = createAsyncThunk(
  'roles/getRolesDataById',
  async (id) => {
    const response = await axios.get(baseUrl() + `/roles/${id}/permissions`, {
      id
    })
    return {
      // totalPages: response.data.count.total,
      params: response.data.params,
      roles: response.data.data
    }
  }
)

export const getAllRoles = createAsyncThunk('roles/addRoles', async () => {
  const response = await axios.get(baseUrl() + '/roles/all')
  return {
    roles: response.data.data
  }
})

export const addRoles = createAsyncThunk(
  'roles/addRoles',
  async ({ roleName, description, permissionId }, { dispatch }) => {
    try {
      const res = await axios.post(baseUrl() + '/roles', {
        roleName,
        description,
        permissionId
      })
      toast.success(res?.data?.message)
      await dispatch(getRolesData({ pageNumber: 1, pageSize: 10 }))
      //return roles
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const updateRoles = createAsyncThunk(
  'roles/updateRoles',
  async ({ roleName, description, permissionId, id }, { dispatch }) => {
    try {
      const res = await axios.put(baseUrl() + `/roles/${id}`, {
        roleName,
        description,
        permissionId
      })
      toast.success(res?.data?.message)
      await dispatch(getRolesData({ pageNumber: 1, pageSize: 10 }))
      //return roles
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const deleteRoles = createAsyncThunk(
  'roles/deleteRoles',
  async ({ id, pageNumber, pageSize }, { dispatch, getState }) => {
    try {
      dispatch(setLoader())
      const res = await axios.delete(baseUrl() + `/roles/${id}`)
        toast.success(res?.data?.message)
      await dispatch(getRolesData({ pageNumber, pageSize }))
      return id
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const rolesSlice = createSlice({
  name: 'roles',
  initialState: {
    roles: [],
    loader: true,
    permissionsOfRole: [],
    totalPages: 0,
    params: {},
    selected: null,
    roles: []
  },
  reducers: {
    selectRoles: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setLoader: (state) => {
      state.loader = !state.loader
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRolesData.fulfilled, (state, action) => {
        state.roles = action.payload.roles
        state.totalPages = action.payload.totalPages
        state.loader = action.payload.loader
      })
      .addCase(getRolesDataById.fulfilled, (state, action) => {
        state.permissionsOfRole = action.payload.roles
      })
      .addCase(getAllRoles.fulfilled, (state, action) => {
        state.roles = action.payload.roles
      })
  }
})

export const { selectRoles, setLoader } = rolesSlice.actions

export default rolesSlice.reducer
