// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl } from './../../../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getDesignationList = createAsyncThunk(
  'designation/getDesignationList',
  async (params) => {
    const response = await axios.get(baseUrl() + '/designation', { params })
    return {
      totalPages: response.data?.count?.total,
      designationList: response.data.data,
    }
  }
)

export const getAllDesignation = createAsyncThunk(
  'designation/getAllDesignation',
  async () => {
    const response = await axios.get(baseUrl() + '/designation')
    return {
      totalPages: response.data?.count?.total,
      allDesignation: response.data.data,
    }
  }
)

export const addDesignation = createAsyncThunk(
  'designation/addDesignation',
  async (designation, { dispatch }) => {
    try {
      const res = await axios.post(baseUrl() + '/designation', designation)
      toast.success(res?.data?.message)
      return designation
    } catch (error) {
      toast.error(error?.response?.data?.message)
      return error
    } finally {
      dispatch(setLoading(false))
    }
  }
)

export const designationSlice = createSlice({
  name: 'designation',
  initialState: {
    designationList: [],
    loader: false,
    totalPages: 0,
    show: false,
    loading:false,
    allDesignation: []
  },
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload || false
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setShow: (state, action) => {
      state.show = action.payload || false
    }
  },
  extraReducers: {
    [getDesignationList.pending] : (state) => {
      state.loader = true
    },
    [getDesignationList.fulfilled]: (state, action) => {
      state.designationList = action.payload.designationList
      state.totalPages = action.payload.totalPages
      state.loader = false
    },
    [getDesignationList.rejected]: (state) => {
      state.loader = false
    },
    [addDesignation.fulfilled]: (state) => {
      state.show = false
    },
    [getAllDesignation.fulfilled]: (state, action) => {
      state.allDesignation = action.payload.allDesignation
    }
  }
})

export const { setLoader, setShow, setLoading } = designationSlice.actions

export default designationSlice.reducer
