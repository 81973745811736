import ReactPaginate from 'react-paginate'
import { Input } from 'reactstrap'
import React from 'react'

const CustomPagination = ({ store, handlePerPage, handlePagination }) => {
  const count = Number(Math.ceil(store?.totalPages / store?.rowsPerPage))

  return (
    <>
      <div className="d-flex align-items-center justify-content-lg-start">
        <label className="ms-2" htmlFor="rows-per-page">
          Show
        </label>
        <Input
          className="mx-50"
          type="select"
          id="rows-per-page"
          value={store?.rowsPerPage}
          onChange={handlePerPage}
          style={{ width: '8rem' }}
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
        </Input>
        <label htmlFor="rows-per-page" style={{ width: '120px' }}>
          / {store?.totalPages} Entries
        </label>
        <ReactPaginate
          className="pagination react-paginate justify-content-end w-100 my-2 pe-1 "
          previousLabel={''}
          nextLabel={''}
          pageCount={count || 1}
          activeClassName="active"
          forcePage={store?.currentPage !== 0 ? store?.currentPage - 1 : 0}
          onPageChange={(pageNo) => handlePagination(pageNo)}
          pageClassName={'page-item'}
          nextLinkClassName={'page-link'}
          nextClassName={'page-item next'}
          previousClassName={'page-item prev'}
          previousLinkClassName={'page-link'}
          pageLinkClassName={'page-link'}
          containerClassName={
            'pagination react-paginate justify-content-end my-2 pe-1'
          }
        />
      </div>
    </>
  )
}

export default CustomPagination
