// ** Dropdowns Imports
import IntlDropdown from './IntlDropdown'
import UserDropdown from './UserDropdown'
import NavbarSearch from './NavbarSearch'
import NotificationDropdown from './NotificationDropdown'

// ** Third Party Components
import { Sun, Moon } from 'react-feather'

// ** Reactstrap Imports
import { NavItem, NavLink } from 'reactstrap'
import MyProfileModal from './MyProfileModal'
import { useState } from 'react'

const NavbarUser = (props) => {
  const [showMyProfile, setShowMyProfile] = useState(false)

  // ** Props
  const { skin, setSkin } = props

  // ** Function to toggle Theme (Light/Dark)
  const ThemeToggler = () => {
    if (skin === 'dark') {
      return <Sun className="ficon" onClick={() => setSkin('light')} />
    } else {
      return <Moon className="ficon" onClick={() => setSkin('dark')} />
    }
  }

  return (
    <ul className="nav navbar-nav align-items-center ms-auto">
      {/* <IntlDropdown /> */}
      <NavItem className="d-none d-lg-block">
        <NavLink className="nav-link-style">
          <ThemeToggler />
        </NavLink>
      </NavItem>
      {/* <NavbarSearch /> */}
      {/* <NotificationDropdown /> */}
      <UserDropdown setShowMyProfile={setShowMyProfile} />
      {showMyProfile ? (
        <MyProfileModal
          isOpen={showMyProfile}
          close={() => setShowMyProfile(false)}
          setShow={setShowMyProfile}
        />
      ) : null}
    </ul>
  )
}
export default NavbarUser
