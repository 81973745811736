// ** React Imports
import { Outlet } from 'react-router-dom'

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from '@layouts/VerticalLayout'

// ** Menu Items Array
import { hasPermission } from '../utility/Utils'
import userSettings from '../navigation/vertical/userSettings'
import apps from '../navigation/vertical/apps'
import dashboards from '../navigation/vertical/dashboards'

const VerticalLayout = (props) => {
  const menuList = () => {
    let temp = [...dashboards, ...apps, ...userSettings]

    temp = temp
      .filter((item) => {
        let bool = false
        item?.show?.map((per) => {
          bool = bool || hasPermission(per)
        })
        return bool
      })
      .map((ele) => {
        return ele?.children
          ? {
              ...ele,
              children: ele?.children?.filter((item) => {
                let bool = false
                 return item?.show ? item?.show?.map((per) => {
                  bool = bool || hasPermission(per)
                }) ? bool : item : item
              })
            }
          : ele
      })

    return temp
  }

  return (
    <Layout menuData={menuList()} {...props}>
      <Outlet />
    </Layout>
  )
}

export default VerticalLayout
