// ** Icons Import
import { FileText, Home } from 'react-feather'
import { IS_ADMIN, IS_MENTOR, IS_RESOURCE } from '../../utility/constants'

export default [
  {
    id: 'dashboards',
    title: 'Dashboard',
    icon: <Home size={20} />,
    badge: 'light-warning',
    navLink: '/dashboard',
    show: [IS_ADMIN, IS_MENTOR, IS_RESOURCE]
  },
  {
    id: 'reviewRoster',
    title: 'Review Roster',
    icon: <Home size={20} />,
    badge: 'light-warning',
    navLink: '/review-roster',
    show: [IS_ADMIN, IS_MENTOR]
  },
  {
    id: 'myTopic',
    title: 'MyTopic',
    icon: <FileText size={20} />,
    navLink: '/apps/myTopic',
    show: [IS_RESOURCE]
  },
  {
    id: 'myTask',
    title: 'My Task',
    icon: <FileText size={20} />,
    navLink: '/apps/myTask',
    show: [IS_RESOURCE]
  }
]
