import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import MyProfile from '../../../../assets/images/logo/MyProfile.svg'
import './MyProfileModal.css'
import { convertDMY, hasPermission } from '../../../../utility/Utils'
import { baseUrl } from '../../../../app.config'
import axios from 'axios'
import { IS_ADMIN } from '../../../../utility/constants'
import Spinner from '@components/spinner/Loading-spinner'

const MyProfileModal = ({ isOpen, close, setShow }) => {
  const [userData, setUserData] = useState({})
  const [loading, setLoading] = useState(true)

  const fetchData = async () => {
    try {
      const response = await axios.get(baseUrl() + '/user/userProfile')
      setUserData(response.data.data)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error)
      setLoading(false)
    }
  }

  const handleModalClosed = () => {
    setShow(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      onClosed={handleModalClosed}
      toggle={() => close()}
      className="modal-dialog-centered modal-md"
      // backdrop="static"
      // keyboard={false}
    >
      <ModalHeader
        className="bg-transparent"
        toggle={(e) => {
          close()
        }}
      ></ModalHeader>
      <ModalBody className="modalBody">
        {loading ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <div className="container">
            <div className="profileDetails1">
              <img
                src={MyProfile}
                className="profileLogo"
                alt="myProfileLogo"
              />
              <h3 className="userName">{userData?.name}</h3>
            </div>

            <div className="profileDetails2">
              <div className="listItems">
                <label className="labelInfo">Email: </label>
                <p className="detailInfo">{userData?.email}</p>
              </div>

              <div className="listItems">
                <label className="labelInfo">Contact No: </label>
                <p className="detailInfo">+91 {userData?.phoneNumber}</p>
              </div>

              {!hasPermission(IS_ADMIN) && (
                <div className="listItems">
                  <label className="labelInfo">Technologies: </label>
                  <p class="technology-container">
                    {userData?.technologyName &&
                      userData?.technologyName
                        .split(',')
                        .map((value, index) => (
                          <span key={index} class="technology detailInfo">
                            {value}
                          </span>
                        ))}
                  </p>
                </div>
              )}

              <div className="listItems">
                <label className="labelInfo">Date of Joining: </label>
                <p className="detailInfo">
                  {userData?.dateOfJoining &&
                    convertDMY(userData?.dateOfJoining)}
                </p>
              </div>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  )
}

export default MyProfileModal
