// ** React Imports
import { lazy } from 'react'
import MentorMaster from '../../views/apps/master/mentorMaster'

const Task = lazy(() => import('../../views/apps/master/task'))
const Module = lazy(() => import('../../views/apps/master/module'))
const Technology = lazy(() =>
  import('./../../views/apps/master/technology/index')
)
const DetailPage = lazy(() =>
  import('../../views/apps/master/user/userDetails/DetailPage')
)
const Project = lazy(() => import('../../views/apps/master/project'))
const UserProject = lazy(() =>
  import('../../views/user-settings/user-project/index')
)
const User = lazy(() => import('../../views/apps/master/user'))
const Category = lazy(() => import('../../views/apps/master/category'))
const Topic = lazy(() => import('../../views/apps/master/topic'))
const SubTopic = lazy(() => import('../../views/apps/master/subtopic'))
const Branch = lazy(() => import('../../views/apps/master/branch'))
const Designation = lazy(() => import('../../views/apps/master/designation'))

const Roles = lazy(() => import('../../views/apps/roles-permissions/roles'))
const Permissions = lazy(() =>
  import('../../views/apps/roles-permissions/permissions')
)
const EmailConfigution = lazy(()=>import('./../../views/apps/roles-permissions/emailConfigurations/index'))
const ReviewsNotDone = lazy(()=>import('./../../views/apps/reports/reviewsNotDone/index'))
const AssignTopic = lazy(() =>
  import('../../views/apps/task&topics/assigntopic')
)
const SessionX = lazy(() => import('../../views/apps/Sessions/index'))
const AssignTask = lazy(() => import('../../views/apps/task&topics/assigntask'))
const ProjectMapping = lazy(() =>
  import('../../views/apps/Project-Mapping/projectMapping')
)
const ProjectMaster = lazy(() =>
  import('../../views/apps/Project-Mapping/projectMaster')
)
const AddRole = lazy(() =>
  import('../../views/apps/roles-permissions/roles/AddRole')
)
const TopicDetailsPage = lazy(() =>
  import(
    '../../views/apps/task&topics/assigntopic/TopicDetails/TopicDetailsPage'
  )
)
const BulkUpload = lazy(() => import('../../views/pages/bulk-upload'))
const TaskDetailsPage = lazy(() =>
  import('../../views/apps/task&topics/assigntask/TaskDetailsPage')
)

const AppRoutes = [
  {
    element: <Roles />,
    path: '/apps/roles',
    meta: {
      layout: 'vertical'
    }
  },
  {
    element: <Permissions />,
    path: '/apps/permissions',
    meta: {
      layout: 'vertical'
    }
  },
  {
    element: <EmailConfigution />,
    path: '/apps/email-configurations',
    meta: {
      layout: 'vertical'
    }
  },
  {
    element: <ReviewsNotDone />,
    path: '/reviews-not-done',
    meta: {
      layout: 'vertical'
    }
  },
  {
    element: <Module />,
    path: '/apps/master/module',
    meta: {
      layout: 'vertical'
    }
  },
  {
    element: <Task />,
    path: '/apps/master/task',
    meta: {
      layout: 'vertical'
    }
  },
  {
    element: <Topic />,
    path: '/apps/master/topic',
    meta: {
      layout: 'vertical'
    }
  },
  {
    element: <SubTopic />,
    path: '/apps/master/subtopic',
    meta: {
      layout: 'vertical'
    }
  },
  {
    element: <Technology />,
    path: '/apps/master/technology',
    meta: {
      layout: 'vertical'
    }
  },
  {
    element: <Category />,
    path: '/apps/master/category',
    meta: {
      layout: 'vertical'
    }
  },
  {
    element: <AssignTopic />,
    path: '/apps/usertopics',
    meta: {
      layout: 'vertical'
    }
  },
  {
    element: <DetailPage />,
    path: '/apps/master/user/details',
    meta: {
      layout: 'vertical'
    }
  },
  {
    element: <User />,
    path: '/apps/master/user',
    meta: {
      layout: 'vertical'
    }
  },
  {
    element: <ProjectMaster />,
    path: '/apps/addproject',
    meta: {
      layout: 'vertical'
    }
  },
  // {
  //   element: <UserProject />,
  //   path: '/usersettings/user-project',
  //   meta: {
  //     layout: 'vertical'
  //   }
  // },
  {
    element: <AssignTask />,
    path: '/apps/usertasks'
  },
  {
    element: <AddRole />,
    path: '/apps/roles/addrole',
    meta: {
      layout: 'vertical'
    }
  },
  {
    element: <TopicDetailsPage />,
    path: '/apps/usertopics/topicdetails',
    meta: {
      layout: 'vertical'
    }
  },
  {
    element: <TopicDetailsPage />,
    path: '/apps/myTopic/topicdetails',
    meta: {
      layout: 'vertical'
    }
  },
  {
    element: <SessionX />,
    path: '/apps/session',
    meta: {
      layout: 'vertical'
    }
  },
  {
    element: <AssignTopic />,
    path: '/apps/myTopic',
    meta: {
      layout: 'vertical'
    }
  },
  {
    element: <AssignTask />,
    path: '/apps/myTask',
    meta: {
      layout: 'vertical'
    }
  },
  {
    element: <TaskDetailsPage />,
    path: '/apps/usertasks/taskdetails',
    meta: {
      layout: 'vertical'
    }
  },
  {
    element: <TaskDetailsPage />,
    path: '/apps/myTask/taskdetails',
    meta: {
      layout: 'vertical'
    }
  },
  {
    element: <Project />,
    path: '/apps/master/project',
    meta: {
      layout: 'vertical'
    }
  },

  {
    element: <MentorMaster />,
    path: '/apps/master/mentormaster',
    meta: {
      layout: 'vertical'
    }
  },
  {
    element: <Branch />,
    path: '/apps/master/branch',
    meta: {
      layout: 'vertical'
    }
  },
  {
    element: <Designation />,
    path: '/apps/master/designation',
    meta: {
      layout: 'vertical'
    }
  }
  // {
  //   element: <BulkUpload />,
  //   path: '/apps/bulkupload',
  //   meta: {
  //     layout: 'vertical'
  //   }
  // }
]

export default AppRoutes
