import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setShowDeletedUser } from './store'
import { memo } from 'react'

const UserTabSwitch = ({ setSearchTerm }) => {
  const dispatch = useDispatch()
  const {showDeletedUser} = useSelector(state=>state?.user)

  useEffect(() => {
    const taeb = document.querySelector('.taeb-switch')
    const taebList = taeb.querySelectorAll('.taeb')

    const handleClick = function () {
      setSearchTerm('')
      const direction = this.getAttribute('taeb-direction')
      direction === 'right'
        ? dispatch(setShowDeletedUser(true))
        : dispatch(setShowDeletedUser(false))

      if (this.classList.contains('active')) return

      taeb.classList.remove('left', 'right')
      taebList.forEach(function (taeb) {
        taeb.classList.remove('active')
      })
      this.classList.add('active')
      taeb.classList.add(direction)
    }

    taebList.forEach(function (taeb) {
      taeb.addEventListener('click', handleClick)
    })

    return () => {
      taebList.forEach(function (taeb) {
        taeb.removeEventListener('click', handleClick)
      })
    }
  }, [])

  return (
    <div className="Tab_wrapper">
      <div className={`taeb-switch ${showDeletedUser ? 'right' : 'left'} text-center`}>
        <div className={`taeb ${showDeletedUser ? '' : 'active'}`} taeb-direction="left">
          Active Employees
        </div>
        <div className={`taeb ${!showDeletedUser ? '' : 'active'}`} taeb-direction="right">
          Deleted Employees
        </div>
      </div>
    </div>
  )
}

export default memo(UserTabSwitch)
