import { lazy } from 'react'

const Dashboard = lazy(() => import('../../views/dashboard'))
const ReviewRoster = lazy(() =>
  import('../../views/review-roster/UserReviewDateTable')
)

const DashboardRoutes = [
  {
    path: '/dashboard',
    element: <Dashboard />
  },
  {
    path: '/review-roster',
    element: <ReviewRoster />
  }
]

export default DashboardRoutes
