// ** React Imports
import { useEffect, useState, Fragment } from 'react'

// ** Table Columns
import { columns } from './columns'

//**Utils */
import { selectThemeColors } from '@utils'

import _ from 'lodash'
// ** Reactstrap Imports
import {
  Alert,
  Row,
  Col,
  Label,
  Form,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap'
import Spinner from '@components/spinner/Loading-spinner'

// ** Store & Actions
import { useDispatch, useSelector } from 'react-redux'
import store, {
  addMentorToUser,
  deleteUserMentor,
  selectMentorMaster,
  updateMentorForUser,
  getAllMentorList,
  getMentorsByUserId,
  getAllMentor,
  setLoader
} from './store'

// ** Third party Components
import classnames from 'classnames'
import ReactPaginate from 'react-paginate'
import DataTable from 'react-data-table-component'
import { useForm, Controller } from 'react-hook-form'
import { ChevronDown, Edit, Trash } from 'react-feather'
import Select from 'react-select'
// import { getAllUserList } from '../../apps/master/user/store'

// ** Styles
import '@styles/react/libs/tables/react-dataTable-component.scss'
import toast from 'react-hot-toast'
import CustomHeader from '../../../../utility/commonComponents/CustomHeader'
import { hasPermission } from '../../../../utility/Utils'
import { memo } from 'react'
import CustomPagination from '../../../../utility/commonComponents/CustomPagination'

const defaultValues = {
  mentorId: [],
  userId: ''
}
const Table = () => {
  // ** Store Vars & Hooks
  const dispatch = useDispatch()
  const userListValue = useSelector((state) => state?.user?.userAllList)
  const store = useSelector((state) => state?.mentorMaster)
  const {
    reset,
    control,
    setError,
    setValue,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues })

  // ** States
  const [data, setData] = useState(null)
  const [show, setShow] = useState(false)
  const [assignedTo, setAssignedTo] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [isEdit, setIsEdit] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  // ** Get data on mount
  useEffect(() => {
    dispatch(
      getAllMentorList({
        pageNo: currentPage,
        pageSize: rowsPerPage,
        search: searchTerm
      })
    )
  }, [])
  // ** Function in get data on page change
  const handlePagination = (pageNo) => {
    setCurrentPage(pageNo.selected + 1)
    dispatch(setLoader(true))
    dispatch(
      getAllMentorList({
        pageNo: pageNo.selected + 1,
        pageSize: rowsPerPage,
        search: searchTerm
      })
    )
  }

  // ** Function in get data on rows per page
  const handlePerPage = (e) => {
    const value = parseInt(e.currentTarget.value)

    dispatch(setLoader(true))
    dispatch(
      getAllMentorList({
        search: searchTerm,
        pageSize: value,
        pageNo: 1
      })
    )
    setRowsPerPage(value)
    setCurrentPage(1)
  }

  const addClick = () => {
    setShow(true), dispatch(getAllMentor())
  }

  // ** Function in get data on search query change
  const handleFilter = (val) => {
    setSearchTerm(val)
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setLoader(true))
      dispatch(
        getAllMentorList({
          pageSize: rowsPerPage,
          pageNo: 1,
          search: searchTerm
        })
      )
      setCurrentPage(1)
    }, 1000)
    return () => {
      clearTimeout(timer)
    }
  }, [searchTerm])

  // ** Function to filter Roles
  const handleAssignedToChange = (val) => {
    setAssignedTo(val)
    dispatch(
      getAllMentorList({
        pageNo: currentPage,
        pageSize: rowsPerPage,
        search: searchTerm
      })
    )
  }

  // ** Custom Pagination

  const renderPaginationComponent = () => {
    let { totalPages } = store
    let param = {
      currentPage,
      totalPages,
      rowsPerPage
    }
    return (
      <CustomPagination
        store={param}
        handlePagination={handlePagination}
        handlePerPage={handlePerPage}
      />
    )
  }

  const dataToRender = () => {
    const filters = {
      search: searchTerm
    }

    const isFiltered = Object.keys(filters).some(function (k) {
      return filters[k].length > 0
    })
    if (store?.mentorsList?.length > 0) {
      return store?.mentorsList
    } else if (store?.mentorsList?.length === 0 && isFiltered) {
      return []
    } else {
      return store?.mentorsList?.slice(0, rowsPerPage)
    }
  }

  //   const mentorOptions = store?.mentorsListData?.map((mentor) => {
  //     return {
  //       label: mentor.mentorName,
  //       value: mentor.mentorId
  //     }
  //   })

  const handleEditClick = async (data) => {
    setIsEdit(true)
    // dispatch(setLoader(true))  // why we use here setLoader(true) -- abhay
    const response = await dispatch(getMentorsByUserId({ id: data.userId }))
    dispatch(getAllMentor())
    // dispatch(getAllUserList())
    dispatch(selectMentorMaster(data))
    setShow(true)
  }

  //   const handleModalClosed = () => {
  //     setIsEdit(false)
  //     setShow(false)
  //     dispatch(selectMentorMaster(null))
  //     reset()
  //   }

  //   const onSubmit = async (data) => {
  //     setData(data)
  //     try {
  //       if (data.userId) {
  //         dispatch(setLoader(true))
  //         let res = false
  //         if (isEdit) {
  //           res = await dispatch(
  //             updateMentorForUser({
  //               mentorId: data.mentorId.map((t) => {
  //                 return t.value
  //               }),
  //               userId: store?.selected?.userId,
  //               pageNo: currentPage,
  //               pageSize: rowsPerPage,
  //               search: searchTerm
  //             })
  //           )
  //         } else {
  //           res = await dispatch(
  //             addMentorToUser({
  //               mentorId: data.mentorId.map((t) => {
  //                 return t.value
  //               }),
  //               userId: data.userId.value,
  //               pageNo: currentPage,
  //               pageSize: rowsPerPage
  //             })
  //           )
  //         }
  //         if (res) {
  //           reset()
  //           setShow(false)
  //         }
  //       } else {
  //         for (const key in data) {
  //           if (data[key] === null) {
  //             setError(key, {
  //               type: 'manual'
  //             })
  //           }
  //           if (data[key] !== null && data[key].length === 0) {
  //             setError(key, {
  //               type: 'manual'
  //             })
  //           }
  //         }
  //       }
  //     } catch (err) {
  //       toast.error(err?.response?.data?.message)
  //     }
  //   }

  const updatedColumns = [
    ...columns

    // {
    //   name: 'Actions',
    //   cell: (row) => {
    //     return (
    //       <div className="d-flex align-items-center permissions-actions">
    //         {hasPermission('MentorForUserEdit') && (
    //           <Button
    //             size="sm"
    //             color="transparent"
    //             className="btn btn-icon actionHover"
    //             onClick={() => handleEditClick(row)}
    //           >
    //             <Edit className="font-medium-2" />
    //           </Button>
    //         )}
    //       </div>
    //     )
    //   }
    // }
  ]

  //   useEffect(() => {
  //     if (store?.selected?.userName) {
  //       setValue('userId', {
  //         label: store?.selected?.userName,
  //         value: store?.selected?.userId
  //       })

  //       if (store?.selected?.mentorName) {
  //         const filterValue = store?.mentorsList?.filter((obj) => {
  //           return store?.selected?.mentorName
  //             .replaceAll(', ', ',')
  //             .split(',')
  //             .includes(obj.mentorName)
  //         })
  //         const mentorIdresult = filterValue?.reduce((uniqueData, obj) => {
  //           const existingObj = uniqueData.find(
  //             (item) => item.label === obj.mentorName
  //           )
  //           if (!existingObj) {
  //             uniqueData.push({ label: obj.mentorName, value: obj.mentorId })
  //           }
  //           return uniqueData
  //         }, [])

  //         setValue('mentorId', mentorIdresult)
  //       }
  //     }
  //   }, [show])

  //   const renderForm = () => {
  //     return (
  //       <Row tag={Form} onSubmit={handleSubmit((data) => onSubmit(data, 'Add'))}>
  //         <Col xs={12} display="flex">
  //           <div className="mb-1">
  //             <Label className="form-label" for="Users">
  //               Employee <span className="text-danger">*</span>
  //             </Label>
  //             <Controller
  //               id="userId"
  //               name="userId"
  //               control={control}
  //               {...register('userId', {
  //                 required: 'Please Select Employee'
  //               })}
  //               render={({ field }) => (
  //                 <Select
  //                   // isDisabled={store?.userId == null && isEdit ? true : false} // why we need to add disable field here for edit ?? need to ask shubham --abhay
  //                   isClearable={false}
  //                   classNamePrefix="select"
  //                   options={userOptions}
  //                   theme={selectThemeColors}
  //                   className={classnames('react-select', {
  //                     'is-invalid': data !== null && data.userId === null
  //                   })}
  //                   {...field}
  //                 />
  //               )}
  //             />
  //             {errors.userId && (
  //               <small className="text-danger">{errors.userId.message}</small>
  //             )}
  //           </div>
  //           <div className="mb-1">
  //             <Label className="form-label" for="mentorId">
  //               Mentor Name <span className="text-danger">*</span>
  //             </Label>
  //             <Controller
  //               id="mentorId"
  //               name="mentorId"
  //               control={control}
  //               {...register('mentorId', {
  //                 required: 'Please Select Mentors'
  //               })}
  //               render={({ field }) => (
  //                 <Select
  //                   isClearable={false}
  //                   classNamePrefix="select"
  //                   isMulti
  //                   options={mentorOptions}
  //                   theme={selectThemeColors}
  //                   className={classnames('react-select', {
  //                     'is-invalid': data !== null && data.mentorId === null
  //                   })}
  //                   {...field}
  //                 />
  //               )}
  //             />
  //             {errors.mentorId && (
  //               <small className="text-danger">{errors.mentorId.message}</small>
  //             )}
  //           </div>
  //         </Col>

  //         <Col xs={12} className="text-center mt-2">
  //           <Button
  //             className="me-1"
  //             color="primary"
  //             type="submit"
  //             disabled={store.loader}
  //           >
  //             {isEdit ? 'Edit Mentor' : 'Add Mentor'}
  //           </Button>
  //           <Button
  //             outline
  //             type="reset"
  //             onClick={handleModalClosed}
  //             disabled={store.loader}
  //           >
  //             Discard
  //           </Button>
  //         </Col>
  //       </Row>
  //     )
  //   }

  return (
    <Fragment>
      <div className="react-dataTable">
        <DataTable
          noHeader
          pagination
          subHeader
          responsive
          paginationServer
          progressComponent={<Spinner />}
          progressPending={store.loader}
          columns={updatedColumns}
          sortIcon={<ChevronDown />}
          className="react-dataTable"
          paginationComponent={renderPaginationComponent}
          data={dataToRender()}
          subHeaderComponent={
            <CustomHeader
              setShow={setShow}
              assignedTo={assignedTo}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              rowsPerPage={rowsPerPage}
              showButton={false}
              handleFilter={handleFilter}
              handlePerPage={handlePerPage}
              //   handleAssignedToChange={handleAssignedToChange}
              dispatch={dispatch}
              // addClick={addClick}
              addName="Assign Mentor"
              title="Mentors-Technology List"
              permissions={hasPermission('MentorToUserAdd')}
            />
          }
        />
      </div>

      {/* <Modal
        isOpen={show}
        onClosed={handleModalClosed}
        toggle={() => setShow(!show)}
        className="modal-dialog-centered"
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader
          className="bg-transparent"
          toggle={() => setShow(!show)}
        ></ModalHeader>
        <ModalBody
          className={classnames({
            'p-3 pt-1': store.selected !== null,
            'px-sm-3 pb-3': store.selected === null
          })}
        >
          <div className="text-center mb-2">
            <h1 className="mb-1">
              {isEdit ? 'Edit' : 'Add'} Mentors to Employee
            </h1>
          </div>
          {renderForm()}
        </ModalBody>
      </Modal> */}
    </Fragment>
  )
}

export default memo(Table)
