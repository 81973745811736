// ** React Imports
import { Fragment } from 'react'

// ** Custom Components
import NavbarUser from './NavbarUser'
import NavbarBookmarks from './NavbarBookmarks'

const ThemeNavbar = (props) => {
  // ** Props
  const {
    skin,
    setSkin,
    setMenuVisibility,
    menuVisibility,
    isHidden,
    setIsHidden
  } = props

  return (
    <Fragment>
      <div className="bookmark-wrapper d-flex align-items-center">
        <NavbarBookmarks
          setMenuVisibility={setMenuVisibility}
          isHidden={isHidden}
          setIsHidden={setIsHidden}
        />
      </div>
      <NavbarUser skin={skin} setSkin={setSkin} />
    </Fragment>
  )
}

export default ThemeNavbar
