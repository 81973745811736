// ** React Imports
import { lazy } from 'react'
import Onboard from '../../views/pages/authentication/Onboard'

const LoginWithOTP = lazy(() =>
  import('../../views/pages/authentication/LoginWithOTP')
)

const Login = lazy(() => import('../../views/pages/authentication/Login'))

const ForgotPassword = lazy(() =>
  import('../../views/pages/authentication/ForgotPassword')
)

const AuthenticationRoutes = [
  {
    path: '/login',
    element: <Login />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: '/loginwithotp',
    element: <LoginWithOTP />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
    layout: 'BlankLayout',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: '/onboard',
    element: <Onboard />,
    layout: 'BlankLayout',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: false
    }
  }
]

export default AuthenticationRoutes
