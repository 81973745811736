import React, { useState, useRef, useEffect } from 'react'
import { Tooltip } from 'reactstrap'

export default function TextWithEllipsis({ string, maxLength }) {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [isMounted, setIsMounted] = useState(false)
  const id = useRef(`tooltip-${Math.random().toString(36).substring(7)}`)

  const displayText =
    string.length > maxLength ? string.slice(0, maxLength) + '...' : string

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen)
  }

  useEffect(() => {
    setIsMounted(true)
    return () => {
      setIsMounted(false)
    }
  }, [])

  return (
    <span>
      {isMounted && (
        <Tooltip
          placement="auto"
          target={id.current}
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
        >
          {string}
        </Tooltip>
      )}
      <span
        id={id.current}
        onMouseEnter={toggleTooltip}
        onMouseLeave={toggleTooltip}
      >
        {displayText}
      </span>
    </span>
  )
}
