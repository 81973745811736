// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl } from '../../../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getTaskList = createAsyncThunk(
  'task/getTaskList',
  async (params) => {
    const response = await axios.get(baseUrl() + '/tasks', { params })
    return {
      totalPages: response.data.count.total,
      params: response.data.params,
      data: response.data.data,
      loader: false
    }
  }
)

export const getTaskListById = createAsyncThunk(
  'task/getTaskListById',
  async ({ taskId }) => {
    const response = await axios.get(baseUrl() + `/tasks/${taskId}`)
    return {
      taskListById: response.data.data
    }
  }
)

export const addTask = createAsyncThunk(
  'task/addTask',
  async (task, { dispatch }) => {
    try {
      let message = ''
      let status = ''
      await axios
        .post(baseUrl() + '/tasks', task, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then((res) => {
          message = res?.data?.message
          status = 'success'
        })
        .catch((err) => {
          message = err?.response?.data?.message
          status = 'error'
        })

      return { message, status }
      //await dispatch(getTaskList())
      // return task
    } catch (error) {
      toast.error(error?.response?.data?.message)
      return false
    } finally {
      dispatch(setLoading(false))
    }
  }
)

export const updateTask = createAsyncThunk(
  'task/updateTask',
  async ({ id, formData }, { dispatch }) => {
    try {
      let message = ''
      let status = ''
      await axios
        .put(baseUrl() + `/tasks/${id}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then((res) => {
          message = res?.data?.message
          status = 'success'
        })
        .catch((err) => {
          message = err?.response?.data?.message
          status = 'error'
        })
      return { message, status }
    } catch (error) {
      toast.error(error?.response?.data?.message)
      return false
    } finally {
      dispatch(setLoading(false))
    }
  }
)

export const getTaskReviewById = createAsyncThunk(
  'task/getTaskReviewById',
  async ({ userId }) => {
    const response = await axios.get(baseUrl() + `/task-review/${userId}`)
    return {
      taskReviewById: response.data.data
    }
  }
)

export const deleteTask = createAsyncThunk(
  'task/deleteTask',
  async ({ id }, { dispatch }) => {
    try {
      dispatch(setLoader())
      const res = await axios.delete(baseUrl() + `/tasks/${id}`)
      toast.success(res?.data?.message)
      await dispatch(getTaskList())
      return id
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const task = createSlice({
  name: 'task',
  initialState: {
    taskList: [],
    selectTask: [],
    taskListById: [],
    taskReviewById: [],
    reload: false,
    loader: true,
    loading: false,
    taskStatus: {},
    totalPages: 0
  },
  reducers: {
    selectTask: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setLoader: (state) => {
      state.loader = !state.loader
    },
    setLoading: (state, action) => {
      state.loading = action?.payload
    }
  },

  extraReducers: {
    [addTask.fulfilled]: (state, action) => {
      state.reload = !state.reload
      state.taskStatus = action.payload
    },
    [getTaskList.fulfilled]: (state, action) => {
      ;(state.taskList = action.payload.data),
        (state.totalPages = action.payload.totalPages)
      state.loader = action.payload.loader
    },
    [getTaskList.pending]: (state, action) => {
      state.loader = true
    },
    [getTaskListById.fulfilled]: (state, action) => {
      state.taskListById = action.payload.taskListById
    },
    [updateTask.fulfilled]: (state, action) => {
      state.reload = !state.reload
      state.taskStatus = action.payload
    },
    [getTaskReviewById.fulfilled]: (state, action) => {
      state.taskReviewById = action.payload.taskReviewById
    },
    [deleteTask.fulfilled]: (state, action) => {
      state.reload = !state.reload
    }
  }
})

export const { selectTask, setLoader, setLoading } = task.actions

export default task.reducer
