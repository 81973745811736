// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../../app.config'

export const permissionByModule = createAsyncThunk(
  'permissions/permissionByModule',
  async (params) => {
    const response = await axios.get(baseUrl() + '/permissions/byModule', {
      params
    })

    const list = response.data.data.map((temp) => {
      var obj = { ...temp, checked: null }

      obj.permissionList = temp.permissionList.map((item) => {
        return {
          ...item,
          checked: false
        }
      })

      return {
        ...obj,
        checked: false
      }
    })
    return {
      permissionListByModule: list
    }
  }
)

export const getAllPermissionList = createAsyncThunk(
  'permissions/getAllPermissionList',
  async (params) => {
    const response = await axios.get(baseUrl() + '/permissions', {
      params
    })

    const list = response.data.data.map((temp) => {
      return {
        ...temp,
        checked: null
      }
    })
    return {
      totalPages: response.data.count.total,
      params: response.data.params,
      permissions: list,
      loader: false
    }
  }
)
export const getPermissionAllList = createAsyncThunk(
  'permissions/getPermissionAllList',
  async (params) => {
    const response = await axios.get(baseUrl() + '/permissions/all', {
      params
    })
    const allList = response.data.data.map((temp) => {
      return {
        ...temp,
        checked: null
      }
    })

    return {
      //totalPages: response.data.count.total,
      //params: response.data.params,
      allPermissions: allList
    }
  }
)
export const addPermission = createAsyncThunk(
  'permissions/addPermission',
  async ({ permission, pageNo, pageSize }, { dispatch }) => {
    try {
      const res = await axios.post(baseUrl() + '/permissions', {
        ...permission
      })
      toast.success(res?.data?.message)
      return permission
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const updatePermission = createAsyncThunk(
  'permissions/updatePermission',
  async ({ permission, pageNo, pageSize }) => {
    try {
      const res = await axios.put(baseUrl() + `/permissions/${permission.id}`, {
        ...permission
      })
      toast.success(res?.data?.message)
      //await dispatch(getAllPermissionList({ pageNo, pageSize }))
      return permission
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const deletePermission = createAsyncThunk(
  'permissions/deletePermission',
  async ({ id, pageNo, pageSize }, { dispatch, getState }) => {
    try {
      dispatch(setLoader())
      const res = await axios.delete(baseUrl() + `/permissions/${id}`)
      toast.success(res?.data?.message)
      await dispatch(getAllPermissionList({ pageNo, pageSize }))
      return id
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: {
    permissions: [],
    allPermissions: [],
    permissionListByModule: [],
    // reload: false,
    loader: true,
    params: {},
    totalPages: 0,
    selected: null
  },
  reducers: {
    selectPermission: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setLoader: (state) => {
      state.loader = !state.loader
    }
  },

  extraReducers: {
    [addPermission.fulfilled]: (state, action) => {
      // state.reload = !state.reload
    },
    [getAllPermissionList.fulfilled]: (state, action) => {
      state.permissions = action.payload.permissions
      state.totalPages = action.payload.totalPages
      state.loader = action.payload.loader
    },
    [getPermissionAllList.fulfilled]: (state, action) => {
      state.allPermissions = action.payload.allPermissions
    },
    [permissionByModule.fulfilled]: (state, action) => {
      state.permissionListByModule = action.payload.permissionListByModule
    }
  }
})

export const { selectPermission, setLoader } = permissionsSlice.actions

export default permissionsSlice.reducer
