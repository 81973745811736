// ** React Imports
import React, { useEffect, useState, Fragment } from 'react'

import _ from 'lodash'
// ** Reactstrap Imports
import { Input } from 'reactstrap'

// ** Store & Actions
import { useDispatch, useSelector } from 'react-redux'

// ** Third party Components
import ReactPaginate from 'react-paginate'
import DataTable from 'react-data-table-component'
import { ChevronDown } from 'react-feather'

// ** Styles
import '@styles/react/libs/tables/react-dataTable-component.scss'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import { hasPermission } from '../../../../src/utility/Utils'
import CustomHeader from '../../../utility/commonComponents/CustomHeader'
import Spinner from '@components/spinner/Loading-spinner'
import UploadForm from './UploadForm'
import { getModuleDropdown } from '../../apps/master/module/store'
import { columns } from './columns'
import {
  getBulkUploadList,
  selectCurrentPage,
  selectRowsPerPage
} from './store'
import { memo } from 'react'

const Table = () => {
  // ** Store Vars & Hooks
  const dispatch = useDispatch()
  const store = useSelector((state) => state?.bulkupload)
  const { loader } = useSelector((state) => state.bulkupload)
  const [show, setShow] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [filter, setFilter] = useState(false)

  useEffect(() => {
    dispatch(getBulkUploadList())
  }, [])

  // ** Function in get data on page change
  const handlePagination = (page) => {
    dispatch(
      getBulkUploadList({
        pageNo: page.selected + 1,
        pageSize: store?.rowsPerPage
      })
    )
    dispatch(selectCurrentPage(page.selected + 1))
  }

  // ** Function in get data on rows per page
  const handlePerPage = (e) => {
    const value = parseInt(e.currentTarget.value)
    dispatch(
      getBulkUploadList({
        pageSize: value,
        pageNo: 1
      })
    )
    dispatch(selectRowsPerPage(value))
    dispatch(selectCurrentPage(1))
  }

  // ** Custom Pagination
  const CustomPagination = () => {
    const count = Number(Math.ceil(store.totalPages / store?.rowsPerPage))

    return (
      <>
        <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
          <label className="ms-2" htmlFor="rows-per-page">
            Show
          </label>
          <Input
            className="mx-50"
            type="select"
            id="rows-per-page"
            value={store?.rowsPerPage}
            onChange={handlePerPage}
            style={{ width: '5rem' }}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
          </Input>
          <label htmlFor="rows-per-page">Entries</label>
          <ReactPaginate
            className="pagination react-paginate justify-content-end w-100 my-2 pe-1 "
            previousLabel={''}
            nextLabel={''}
            pageCount={count || 1}
            activeClassName="active"
            forcePage={store?.currentPage !== 0 ? store?.currentPage - 1 : 0}
            onPageChange={(pageNo) => handlePagination(pageNo)}
            pageClassName={'page-item'}
            nextLinkClassName={'page-link'}
            nextClassName={'page-item next'}
            previousClassName={'page-item prev'}
            previousLinkClassName={'page-link'}
            pageLinkClassName={'page-link'}
            containerClassName={
              'pagination react-paginate justify-content-end my-2 pe-1'
            }
          />
        </div>
      </>
    )
  }
  const addClick = (data) => {
    setShow(true), dispatch(getModuleDropdown())
  }

  // ** Table data to render
  const dataToRender = () => {
    const filters = {
      search: searchTerm
    }

    const isFiltered = Object.keys(filters).some(function (k) {
      return filters[k].length > 0
    })
    if (store?.bulkUploadList?.length > 0) {
      return store?.bulkUploadList
    } else if (store?.bulkUploadList?.length === 0 && isFiltered) {
      return []
    } else {
      return store?.bulkUploadList?.slice(0, store?.rowsPerPage)
    }
  }

  return (
    <Fragment>
      {/* <DeleteModal {...deletePropsConfig}></DeleteModal> */}

      <div className="react-dataTable">
        <DataTable
          noHeader
          pagination
          subHeader
          responsive
          progressComponent={<Spinner />}
          progressPending={loader}
          paginationServer
          columns={columns}
          sortIcon={<ChevronDown />}
          className="react-dataTable"
          paginationComponent={CustomPagination}
          data={dataToRender()}
          subHeaderComponent={
            <CustomHeader
              setShow={setShow}
              rowsPerPage={store?.rowsPerPage}
              dispatch={dispatch}
              setFilter={setFilter}
              clearFilter={store.clearFilter}
              permissions={hasPermission('UserBulk')}
              addClick={addClick}
              addName="Upload"
              title="Bulk Upload"
            />
          }
        />

        <UploadForm setShow={setShow} show={show} />
      </div>
    </Fragment>
  )
}
export default memo(Table)
